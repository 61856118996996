import React from 'react';
import { MdLocalPhone } from 'react-icons/md';
import { FaLocationDot } from 'react-icons/fa6';
import { IoMdMail } from 'react-icons/io';

function Contact() {
    return ( 
        <div className='pt-20'>
            <div className=" font-bold text-4xl space-x-8 text-center text-red-500 pt-20">
                <h1>Contact Us</h1>
            </div>
            <div className="grid sm:grid-cols-2 items-center gap-20 my-6 mx-auto max-w-4xl bg-white text-[#333] font-[sans-serif]">
                <div>
                    <div className="mt-12">
                        <h2 className="text-lg text-red-600 font-extrabold">Call</h2>
                        <ul className="mt-3">
                            <li className="flex items-center">
                                <div className="bg-[#e6e6e6cf] h-10 w-10 rounded-full flex items-center justify-center shrink-0">
                                    <MdLocalPhone className="text-red-600 text-xl" aria-label="Phone Icon" />
                                </div>
                                <a href="tel:+919423001415" className="text-gray-900 text-sm ml-3">
                                    <small className="block font-bold">Call</small>
                                    <strong className="text-lg font-bold">+91 7350314001</strong>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="mt-12">
                        <h2 className="text-lg text-red-600 font-extrabold">Email</h2>
                        <ul className="mt-3">
                            <li className="flex items-center">
                                <div className="bg-[#e6e6e6cf] h-10 w-10 rounded-full flex items-center justify-center shrink-0">
                                    <IoMdMail className="text-red-600 text-xl" aria-label="Mail Icon" />
                                </div>
                                <a href="mailto:samvedanafoundation.sangli@gmail.com" className="text-gray-900 text-sm ml-3">
                                    <small className="block font-bold">Mail</small>
                                    <strong className="text-lg font-bold">communicationsargam@gmail.com</strong>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="mt-12">
                        <h2 className="text-lg text-red-600 font-extrabold">Address</h2>
                        <ul className="mt-3">
                            <li className="flex items-center">
                                <div className="bg-[#e6e6e6cf] h-10 w-10 rounded-full flex items-center justify-center shrink-0">
                                    <FaLocationDot className="text-red-600 text-xl" aria-label="Location Icon" />
                                </div>
                                <div className="text-gray-900 text-sm ml-3">
                                    <small className="block font-bold">Address</small>
                                    <strong className="text-lg font-bold">Shop no. 1, Gheware Icon, St Stand Rd, Gaon Bhag, Sangli, Sangli Miraj Kupwad, Maharashtra 416416
                                    </strong>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className="flex space-x-4 mb-4 mt-4">
                        <a href="tel:+917350314001" className="bg-red-600 hover:bg-red-800 text-white py-2 px-4 rounded">Call</a>
                        <a href="https://maps.app.goo.gl/U2dHvizUnrP7LaKV8" target="_blank" rel="noopener noreferrer" className="bg-red-600 hover:bg-red-800 text-white py-2 px-4 rounded">Get Direction</a>
                    </div>
                </div>
                <form className="ml-auto space-y-4">
                    <h1 className="text-red-600 font-bold text-xl">Enquire now</h1>
                    <input type="text" placeholder="Name" className="w-full rounded-md py-3 px-4 bg-gray-100 text-sm focus:outline-red-600" />
                    <input type="email" placeholder="Email" className="w-full rounded-md py-3 px-4 bg-gray-100 text-sm focus:outline-red-600" />
                    <input type="text" placeholder="Subject" className="w-full rounded-md py-3 px-4 bg-gray-100 text-sm focus:outline-red-600" />
                    <textarea placeholder="Message" rows="6" className="w-full rounded-md px-4 bg-gray-100 text-sm pt-3 focus:outline-red-600"></textarea>
                    <button type="submit" className="text-white bg-red-500 hover:bg-red-800 font-semibold rounded-md text-sm px-4 py-3 w-full">Send</button>
                </form>
            </div>
            <div className="relative w-full h-96">
                <iframe
                    className="absolute top-0 left-0 w-full h-full"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d122190.12592982431!2d74.48055242463394!3d16.854043958024818!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc118446dd0cfb3%3A0xb3ed223229bce7ea!2sSargam%20Communication!5e0!3m2!1sen!2sin!4v1726135543053!5m2!1sen!2sin"                    frameBorder="0"
                    style={{ border: 0 }}
                    allowFullScreen
                    aria-hidden="false"
                    tabIndex="0"
                ></iframe>
            </div>
        </div>
    );
}

export default Contact;
