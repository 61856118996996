import React from 'react';
import imgjpg from '../../../img/image0.jpeg';

function Homepageunder() {
  return (
    <div className="pt-16">
      <section className="bg-gray-50 py-20">
        <div className="container mx-auto px-4 flex flex-col lg:flex-row items-center lg:space-x-8">
          <div className="lg:w-1/2">
            <h1 className="text-4xl font-bold text-gray-800 leading-tight">
              Mobiles, TVs, Accessories & More – All Under One Roof At <span className="text-red-500">Sargam Communication</span>
            </h1>
            <h4 className="text-xl mt-2 text-gray-700">Your Trusted Mobile & Gadget Hub in Sangli!</h4>
            <p className="mt-6 text-gray-600 text-lg">
              At Sargam Communication, we specialize in the latest mobiles, TVs, accessories, and gadgets, along with a variety of used phones for budget-conscious buyers. Our commitment is to deliver top-notch service and ensure that every customer leaves our store completely satisfied. Visit us today for an exceptional shopping experience tailored to your needs!
            </p>
            <div className="mt-8 flex space-x-4">
              <a href="/" className="bg-red-500 text-white px-6 py-3 rounded-full hover:bg-red-600 transition duration-300">
                Popular Services
              </a>
              <a href="/" className="border border-gray-300 text-gray-700 px-6 py-3 rounded-full hover:bg-gray-100 transition duration-300">
                Get Started
              </a>
            </div>
          </div>

          <div className="lg:w-1/2 mt-10 lg:mt-0">
            <img src={imgjpg} alt="Business People" className="w-full rounded-lg shadow-lg object-cover" />
          </div>
        </div>
      </section>
    </div>
  );
}

export default Homepageunder;
