import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './Component/Home/Home';
import About from './Component/About/About';
import Service from './Component/Service/Service';
import Contact from './Component/Contact/Contact';
import Navbar from './Component/Navbar/Navbar';
import Footer from './Component/Contact/Footer';
import MobileForCard from './Component/Service/Mobile/MobileForCard';
import WatchforCard from './Component/Service/Watch/WatchforCard';
import Accessoriesforcard from './Component/Service/TV/Accessoriesforcard';
import HeadSet from './Component/Service/Headset/HeadSet';
import PowerBank from './Component/Service/Powerbank/PowerBank';
import Cable from './Component/Service/Cable/Cable';
import UsedMobile from './Component/Service/UsedMobile/UsedMobile';
import SmartTV from './Component/Service/SmartTV/SmartTV';


function App() {
  return (
    <div>
    <Router>
      <Navbar />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/about' element={<About />} />
        <Route path='/service' element={< Service/>} />
        <Route path='/contact' element={<Contact />} />     
        <Route path='/mobile' element={<MobileForCard />} />
        <Route path='/smart_watches' element={< WatchforCard/>} />
        <Route path='/headsets' element={< HeadSet/>} />
        <Route path='/powerbanks' element={<PowerBank />} />
        <Route path='/cables_&_chargers' element={< Cable/>} />  
        <Route path='/used_mobile' element={< UsedMobile/>} />  
        <Route path='/smart_tv' element={< SmartTV/>} />  

      </Routes>
      <Footer/>
    </Router>
   
  </div>
  );
}

export default App;
