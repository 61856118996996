import React, { useState, useEffect } from 'react';
import axios from 'axios';

function HeadSet() {
    const [products, setProducts] = useState([]);
    const [cart, setCart] = useState([]);

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const response = await axios.get('https://sargamcommunication.com/exe_files/list_headset.php'); 
                setProducts(response.data); 
            } catch (error) {
                console.error('Error fetching products:', error);
            }
        };

        fetchProducts();
    }, []);

    const addToCart = (product) => {
        setCart([...cart, product]);
    };

    return (
        <div className="container mx-auto px-4 py-16 sm:py-20 md:py-24 lg:py-32">
            <h1 className="text-3xl sm:text-4xl md:text-5xl font-bold text-center mb-8">
                Latest <span className="text-red-500">Headsets</span>
            </h1>

            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
                {products.map((product) => (
                    <div key={product.id} className="bg-white shadow-md rounded-lg p-4 mb-6 hover:shadow-lg transition-shadow">
                        <img
                        src={`https://sargamcommunication.com/dashboard/images/products/${product.PhotoPath}`}
                        alt={product.ProductName}
                            className="w-full h-48 sm:h-64 object-contain transition-transform duration-300 ease-in-out transform hover:scale-110"
                        />

                        <h2 className="text-lg font-semibold mt-4 text-center">{product.ProductName}</h2>

                        <div className="flex justify-between items-center mt-2">
                            <span className="text-red-500 font-bold">₹{product.OfferPrice}</span>
                            {product.MRP && (
                                <span className="line-through text-gray-400">₹{product.MRP}</span>
                            )}
                        </div>

                        <div className="flex items-center mt-2 justify-center">
                            <span className="text-yellow-400">★★★★★</span>
                            <span className="ml-2 text-gray-500">({product.reviews} Reviews)</span>
                        </div>

                        <button
                            onClick={() => addToCart(product)}
                            className={`w-full mt-4 py-2 rounded-lg text-white ${cart.includes(product) ? 'bg-green-500' : 'bg-blue-500'
                                } hover:bg-opacity-90 transition`}
                        >
                            {cart.includes(product) ? 'Buy Now' : 'Enquire Now'}
                        </button>
                    </div>
                ))}
            </div>

            <div className="mt-8 text-center">
                <button className="px-6 py-2 bg-black text-white rounded-lg hover:bg-gray-800">
                    View All Products
                </button>
            </div>
        </div>
    );
}

export default HeadSet;
