import React from 'react';
import logo from '../../img/SARGAM_NEW_LOGO_copy-removebg-preview.png';
import social1 from '../../img/facebook-color.svg';
import social2 from '../../img/whatsapp.svg';
import social3 from '../../img/instagram.svg';
import social4 from '../../img/twitter.svg';
import social5 from '../../img/youtube-round-2.svg';

const Footer = () => {
  return (
    <footer className="bg-white dark:bg-gray-900">
      <div className="mx-auto w-full max-w-screen-xl p-4 py-6 lg:py-8">
        <div className="md:flex md:justify-between">
          <div className="mb-6 md:mb-0 mr-20">
            <img src={logo} className="h-20 " alt="Company Logo" />
          </div>
          <div className="grid grid-cols-2 gap-8 sm:gap-6 sm:grid-cols-3">
            <div>
              <h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">Quick Links</h2>
              <ul className="text-gray-500 dark:text-gray-400 font-medium">
                <li className="mb-4">
                  <a href="/about" className="hover:underline" aria-label="About Us">About</a>
                </li>
                <li>
                  <a href="/contact" className="hover:underline" aria-label="Contact Us">Contact us</a>
                </li>
              </ul>
            </div>
            <div>
              <h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">Our Services</h2>
              <ul className="text-gray-500 dark:text-gray-400 font-medium">
                <li className="mb-4">
                  <a href="/mobiles" className="hover:underline" aria-label="Mobiles">Mobiles</a>
                </li>
                <li>
                  <a href="/watches" className="hover:underline" aria-label="Watches">Watches</a>
                </li>
                <li>
                  <a href="/accessories" className="hover:underline" aria-label="Accessories">Accessories</a>
                </li>
              </ul>
            </div>
            <div>
              <h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">Legal</h2>
              <ul className="text-gray-500 dark:text-gray-400 font-medium">
                <li className="mb-4">
                  <a href="tel:+917350314001" className="hover:underline" aria-label="Phone Number">+91 7350314001</a>
                </li>
                <li>
                  <a href="https://maps.app.goo.gl/U2dHvizUnrP7LaKV8" className="hover:underline" aria-label="Address">Shop no. 1, Gheware Icon, St Stand Rd, Gaon Bhag, Sangli</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
        <div className="sm:flex sm:items-center sm:justify-between">
          <div className="text-gray-400 mb-6 md:mb-0 text-center md:text-left">
            <p>© 2024 Sargam Communication. All rights reserved.</p>
            <p>Powered by <a href="https://www.businessmantra.info/" className="text-gray-200 hover:text-gold">Business Mantra</a></p>
          </div>
          <div>
            <h2 className="text-lg font-semibold mb-4 text-white">Follow Us</h2>
            <div className="flex space-x-4">
              {/* Facebook */}
              <a href="https://www.facebook.com/sargamcommunicationsangli/" target="_blank" rel="noopener noreferrer" aria-label="Facebook">
                <img src={social1} alt="Facebook" className="w-8 h-auto hover:scale-110 transition duration-200" />
              </a>

              {/* WhatsApp */}
              <a href="https://wa.me/+917350314001" target="_blank" rel="noopener noreferrer" aria-label="WhatsApp">
                <img src={social2} alt="WhatsApp" className="w-8 h-auto hover:scale-110 transition duration-200" />
              </a>

              {/* Instagram */}
              <a href="https://www.instagram.com/sargamcommunication/" target="_blank" rel="noopener noreferrer" aria-label="Instagram">
                <img src={social3} alt="Instagram" className="w-8 h-auto hover:scale-110 transition duration-200" />
              </a>

              {/* Twitter */}
              <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer" aria-label="Twitter">
                <img src={social4} alt="Twitter" className="w-8 h-auto hover:scale-110 transition duration-200" />
              </a>

              {/* YouTube */}
              <a href="https://www.youtube.com/channel/UCGer0SjeSXYkQpcYb0B96kg" target="_blank" rel="noopener noreferrer" aria-label="YouTube">
                <img src={social5} alt="YouTube" className="w-8 h-auto hover:scale-110 transition duration-200" />
              </a>
            </div>
          </div>

        </div>
      </div>
    </footer>
  );
}

export default Footer;
