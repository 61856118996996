import React, { useState, useEffect } from 'react';
import axios from 'axios';

function WatchforCard() {
    const [products, setProducts] = useState([]);
    const [cart, setCart] = useState([]);

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const response = await axios.get('https://sargamcommunication.com/exe_files/list_watches.php');
                setProducts(response.data);
            } catch (error) {
                console.error('Error fetching products:', error);
            }
        };

        fetchProducts();
    }, []);

    const addToCart = (product) => {
        setCart([...cart, product]);
    };

    // Function to handle WhatsApp inquiry
    const handleWhatsAppInquiry = (product) => {
        const message = `Hello, I am interested in the watch: ${product.ProductName}. Can you please provide more details?`;
        const whatsappUrl = `https://wa.me/919876543210?text=${encodeURIComponent(message)}`; // Replace '919876543210' with your business WhatsApp number
        window.open(whatsappUrl, '_blank'); // Opens WhatsApp with the pre-filled message
    };

    return (
        <div className="container mx-auto px-4 py-32">
            <h1 className="text-3xl font-bold text-center mb-8">
                <span className="text-red-500 text-5xl">Watches</span> Collection
            </h1>

            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
                {products.map((product) => (
                    <div
                        key={product.id}
                        className="bg-white shadow-md rounded-lg p-4 mb-6 hover:shadow-lg transition-shadow flex flex-col justify-between h-full"
                        style={{ minHeight: '400px' }} // Ensures consistent card height
                    >
                        <img
                            src={`https://sargamcommunication.com/dashboard/images/products/${product.PhotoPath}`}
                            alt={product.ProductName}
                            className="w-full h-48 object-contain transition-transform duration-300 ease-in-out transform hover:scale-110"
                        />

                        <div className="flex-grow">
                            <h2 className="text-lg font-semibold mt-4">{product.ProductName}</h2>

                            <div className="flex justify-between items-center mt-2">
                                <span className="text-red-500 font-bold">₹{product.OfferPrice}</span>
                                {product.MRP && (
                                    <span className="line-through text-gray-400">₹{product.MRP}</span>
                                )}
                            </div>

                            <div className="flex items-center mt-2">
                                <span className="text-yellow-400">★★★★★</span>
                                <span className="ml-2 text-gray-500">({product.reviews} Reviews)</span>
                            </div>
                        </div>

                        <button
                            onClick={() => cart.includes(product) ? addToCart(product) : handleWhatsAppInquiry(product)}
                            className={`w-full mt-4 py-2 rounded-lg text-white ${
                                cart.includes(product) ? 'bg-green-500' : 'bg-blue-500'
                            } hover:bg-opacity-90 transition`}
                        >
                            {cart.includes(product) ? 'Buy Now' : 'Enquire Now'}
                        </button>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default WatchforCard;
