import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function Homepage() {
  const [slides, setSlides] = useState([]);

  // Fetching slides from the backend API
  useEffect(() => {
    const fetchSlides = async () => {
      try {
        const response = await fetch('https://sargamcommunication.com/exe_files/banners.php'); // Replace with your API URL
        const data = await response.json();
        setSlides(data); // API directly returns an array of slides
      } catch (error) {
        console.error('Error fetching slides:', error);
      }
    };

    fetchSlides();
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    fade: true,
    cssEase: "linear"
  };

  const baseImagePath = 'https://sargamcommunication.com/dashboard/images/banners/'; // The base path where the images are stored

  return (
    <div className='container mx-auto pt-28'>
      {slides.length > 0 ? (
        <Slider {...settings}>
          {slides.map((slide, index) => (
            <div key={index}>
              <img 
                src={`${baseImagePath}${slide.PhotoPath}`} 
                alt={`Slide ${slide.BannerId}`} 
                className="w-full h-auto object-cover" 
              />
            </div>
          ))}
        </Slider>
      ) : (
        <p>Loading slides...</p>
      )}
    </div>
  );
}

export default Homepage;
