import React from 'react';
import brand1 from '../../../img/Apple-Logo-final.jpg';
import brand2 from '../../../img/Samsung-Logo-final.jpg';
import brand3 from '../../../img/Smartphone-Shop-By-Brands-OnePlus.jpg';
import brand4 from '../../../img/Shop-By-Brands-Oppo.jpg';
import brand5 from '../../../img/Logo-Mi.jpg';
import brand6 from '../../../img/Vivo-Logo-final.jpg';
import brand7 from '../../../img/Smartphone-Shop-By-Brands-Realme.jpg';
import brand8 from '../../../img/Motorola-Logo-final.jpg';
import brand9 from '../../../img/Nothing-1.jpg.webp';

function Company() {
    return (
        <div className="pt-16">
            <div className="text-center mb-10">
                <h1 className="text-4xl font-bold text-gray-800 leading-tight">
                    Find Your Favorite Brands at <span className="text-red-500">Sargam Communication</span>
                </h1>
                <p className="mt-4 text-gray-600">Explore the best mobile and gadget brands available at our store</p>
            </div>

            <div className="container mx-auto px-4">
                <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-8">
                    <div className="flex justify-center">
                        <img src={brand1} alt="Apple" className="w-full h-auto object-contain" />
                    </div>
                    <div className="flex justify-center">
                        <img src={brand2} alt="Samsung" className="w-full h-auto object-contain" />
                    </div>
                    <div className="flex justify-center">
                        <img src={brand3} alt="OnePlus" className="w-full h-auto object-contain" />
                    </div>
                    <div className="flex justify-center">
                        <img src={brand4} alt="Oppo" className="w-full h-auto object-contain" />
                    </div>
                    <div className="flex justify-center">
                        <img src={brand5} alt="Mi" className="w-full h-auto object-contain" />
                    </div>
                    <div className="flex justify-center">
                        <img src={brand6} alt="Vivo" className="w-full h-auto object-contain" />
                    </div>
                    <div className="flex justify-center">
                        <img src={brand7} alt="Realme" className="w-full h-auto object-contain" />
                    </div>
                    <div className="flex justify-center">
                        <img src={brand8} alt="Motorola" className="w-full h-auto object-contain" />
                    </div>
                    <div className="flex justify-center">
                        <img src={brand9} alt="Nothing" className="w-full h-auto object-contain" />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Company;
