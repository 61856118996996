import React, { useState, useEffect } from 'react';
import axios from 'axios';

function Mobile() {
    const [products, setProducts] = useState([]);
    const [cart, setCart] = useState([]);
    const [loading, setLoading] = useState(true); // Loading state
    const [error, setError] = useState(null);    // Error state

    // Fetch products from the backend
    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const response = await axios.get('https://sargamcommunication.com/exe_files/list_headset.php');
                const allProducts = response.data;

                // Add the Image URL to each product
                const updatedProducts = allProducts.map(product => ({
                    ...product,
                    Image: `https://sargamcommunication.com/dashboard/images/products/${product.PhotoPath}`
                }));

                // Sorting and limiting the products
                const sortedProducts = updatedProducts.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
                const latestProducts = sortedProducts.slice(0, 4);

                setProducts(latestProducts);
            } catch (error) {
                setError('Error fetching products. Please try again later.');
                console.error('Error fetching products:', error);
            } finally {
                setLoading(false); // Set loading to false after data fetch attempt
            }
        };

        fetchProducts();
    }, []);

    const addToCart = (product) => {
        // Prevent duplicate entries in the cart
        if (!cart.some((item) => item.id === product.id)) {
            setCart([...cart, product]);
        }
    };

    if (loading) {
        return <div className="text-center py-8">Loading products...</div>;
    }

    if (error) {
        return <div className="text-center py-8 text-red-500">{error}</div>;
    }

    return (
        <div className="container mx-auto px-4 py-8">
           <h1 className="text-3xl font-bold text-center mb-8">
                Latest Accessories <span className='text-red-500 text-5xl'>Earbuds,Speakers,Earphones</span> Trends: <span className='font-sans italic'>Must-Have Watches of the Year</span>
            </h1>


            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
                {products.map((product) => (
                    <div key={product.id} className="bg-white shadow-md rounded-lg p-4 mb-6 hover:shadow-lg transition-shadow h-[450px]"> {/* Fixed height */}
                        <img
                            src={product.Image}
                            alt={product.ProductName} 
                            className="w-full h-48 object-contain transition-transform duration-300 ease-in-out transform hover:scale-110" // Fixed image height
                        />

                        <h2 className="text-lg font-semibold mt-4 h-12 overflow-hidden">{product.ProductName}</h2> {/* Fixed title height */}

                        <div className="flex justify-between items-center mt-2">
                            <span className="text-red-500 font-bold">₹{product.OfferPrice}</span>
                            {product.MRP && (
                                <span className="line-through text-gray-400">₹{product.MRP}</span>
                            )}
                        </div>

                        <div className="flex items-center mt-2">
                            <span className="text-yellow-400">★★★★★</span>
                            <span className="ml-2 text-gray-500">({product.reviews} Reviews)</span>
                        </div>

                        <button
                            onClick={() => addToCart(product)}
                            className={`w-full mt-4 py-2 rounded-lg text-white ${cart.includes(product) ? 'bg-green-500' : 'bg-blue-500'
                                } hover:bg-opacity-90 transition`}
                        >
                            {cart.includes(product) ? 'Buy Now' : 'Enquire Now'}
                        </button>
                    </div>
                ))}
            </div>

            <div className="mt-8 text-center">
                <button className="px-6 py-2 bg-black text-white rounded-lg hover:bg-gray-800">
                    View All Products
                </button>
            </div>
        </div>
    );
}

export default Mobile;
