import React, { useState } from 'react';
import logo from '../../img/SARGAM_NEW_LOGO_copy-removebg-preview.png';
import { Link } from 'react-router-dom';
import { FaHeadphones } from "react-icons/fa";
import { MdWatch } from "react-icons/md";
import { RiBattery2ChargeFill } from "react-icons/ri";
import { LuCable } from "react-icons/lu";

const Navbar = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <nav className="bg-white border-b border-gray-200 shadow-lg fixed w-full z-50">
      <div className="container mx-auto px-4 flex justify-between items-center py-4">
        {/* Logo */}
        <a href="/" className="text-xl font-bold flex items-center">
          <img src={logo} alt="Logo" className="h-12 w-auto mr-2" />
        </a>

        {/* Main Links for Desktop */}
        <div className="hidden md:flex space-x-8">
          <Link to='/' className="text-gray-700 hover:text-blue-600">Home</Link>
          <Link to='/about' className="text-gray-700 hover:text-blue-600">About Us</Link>
          <Link to='/mobile' className="text-gray-700 hover:text-blue-600">Mobiles</Link>
          <Link to='/used_mobile' className="text-gray-700 hover:text-blue-600">Used Mobiles</Link>
          <Link to='/smart_tv' className="text-gray-700 hover:text-blue-600">Smart TV</Link>

          {/* Dropdown for Accessories */}
          <div className="relative">
            <button
              onClick={() => setDropdownOpen(!dropdownOpen)}
              className="text-gray-700 hover:text-blue-600 flex items-center focus:outline-none"
            >
              Accessories
              <svg
                className={`w-5 h-5 ml-1 transform transition-transform duration-300 ${dropdownOpen ? 'rotate-180' : 'rotate-0'}`}
                fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"
              >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
              </svg>
            </button>

            {/* Dropdown Menu */}
            <ul
              className={`absolute top-full left-0 mt-2 bg-white border rounded-lg shadow-lg w-56 py-2 transform transition-all duration-300 ease-out ${
                dropdownOpen ? 'opacity-100 translate-y-0' : 'opacity-0 -translate-y-2 pointer-events-none'
              }`}
            >
              <Link to='/smart_watches'> 
                <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer flex items-center space-x-2">
                  <MdWatch className="w-5 h-5" />
                  <span>Smart Watches</span>
                </li>
              </Link>
              <Link to='/headsets'>
                <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer flex items-center space-x-2">
                  <FaHeadphones className="w-5 h-5" />
                  <span>Headsets</span>
                </li>
              </Link>
              <Link to='/powerbanks'>
                <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer flex items-center space-x-2">
                  <RiBattery2ChargeFill className="w-5 h-5" />
                  <span>Powerbanks</span>
                </li>
              </Link>
              <Link to='/cables_&_chargers'>
                <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer flex items-center space-x-2">
                  <LuCable className="w-5 h-5" />
                  <span>Cables & Chargers</span>
                </li>
              </Link>
            </ul>
          </div>

          <Link to='/contact' className="text-gray-700 hover:text-blue-600">Contact Us</Link>
        </div>

        {/* Admin Button */}
        <div className="hidden md:block">
          <a href="https://sargamcommunication.com/dashboard/" className="bg-red-500 text-white px-4 py-2 rounded-full">Admin</a>
        </div>

        {/* Mobile Menu Button */}
        <button 
          className="md:hidden text-gray-700 focus:outline-none" 
          onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
        >
          <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
          </svg>
        </button>
      </div>

      {/* Mobile Menu */}
      <div 
        className={`md:hidden transition-transform duration-300 ease-out bg-white shadow-lg ${
          mobileMenuOpen ? 'max-h-screen' : 'max-h-0 overflow-hidden'
        }`}
      >
        <div className="px-4 py-2">
          <Link to='/' className="block py-2 text-gray-700 hover:text-blue-600">Home</Link>
          <Link to='/about' className="block py-2 text-gray-700 hover:text-blue-600">About Us</Link>
          <Link to='/mobile' className="block py-2 text-gray-700 hover:text-blue-600">Mobiles</Link>
          <Link to='/used_mobile' className="block py-2 text-gray-700 hover:text-blue-600">Used Mobiles</Link>
          <Link to='/smart_tv' className="block py-2 text-gray-700 hover:text-blue-600">Smart TV</Link>

          {/* Dropdown for Accessories */}
          <button 
            onClick={() => setDropdownOpen(!dropdownOpen)} 
             
            className="text-gray-700 hover:text-blue-600 flex items-center py-2"
          >
            Accessories
            <svg 
              className={`w-5 h-5 ml-1 transform transition-transform duration-300 ${dropdownOpen ? 'rotate-180' : 'rotate-0'}`} 
              fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
            </svg>
          </button>

          {/* Dropdown for Mobile Menu */}
          {dropdownOpen && (
            <ul className="pl-4">
              <Link to='/smart_watches'>
                <li className="px-4 py-2 hover:bg-gray-100 flex items-center space-x-2">
                  <MdWatch className="w-5 h-5" />
                  <span>Smart Watches</span>
                </li>
              </Link>
              <Link to='/headsets'>
                <li className="px-4 py-2 hover:bg-gray-100 flex items-center space-x-2">
                  <FaHeadphones className="w-5 h-5" />
                  <span>Headsets</span>
                </li>
              </Link>
              <Link to='/powerbanks'>
                <li className="px-4 py-2 hover:bg-gray-100 flex items-center space-x-2">
                  <RiBattery2ChargeFill className="w-5 h-5" />
                  <span>Powerbanks</span>
                </li>
              </Link>
              <Link to='/cables_&_chargers'>
                <li className="px-4 py-2 hover:bg-gray-100 flex items-center space-x-2">
                  <LuCable className="w-5 h-5" />
                  <span>Cables & Chargers</span>
                </li>
              </Link>
            </ul>
          )}

          <Link to='/contact' className="block py-2 text-gray-700 hover:text-blue-600">Contact Us</Link>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
