import React from 'react'
import Homepage from './Homepage'
import Homepageunder from './Homepage/Homepageunder'
import Company from './Homepage/Company'
import Mobile from '../Service/Mobile/Mobile'
import Watch from '../Service/Watch/Watch'
import Accessories from '../Service/TV/Accessories'
import Contact from '../Contact/Contact'

function Home() {
  return (
    <div>
      <Homepage/>
      <Homepageunder/>
      <Company/>
      <Mobile/>
      <Watch/>
      <Accessories/>
    </div>
  )
}

export default Home