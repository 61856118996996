import React from 'react';
import about1 from '../../img/about2.jpeg';
import about2 from '../../img/image0.jpeg';
import { FaMobileAlt, FaShieldAlt, FaUserFriends, FaDollarSign } from 'react-icons/fa';

function AboutUs() {
  return (
    <div className="min-h-screen bg-gray-100 p-8 pt-28">
      <div className='max-w-5xl mx-auto'>
        <header className="mb-12">
          <h1 className="text-4xl font-bold text-center">Welcome to <span className='text-red-500 text-5xl'>Sargam Communication!</span></h1>
        </header>
        <div className="flex flex-col md:flex-row mb-8 gap-8">
          <section className="flex-1">
            <h2 className="text-3xl font-semibold text-red-500 mb-4">Business Summary</h2>
            <p className="text-gray-700 text-lg leading-relaxed">
              Founded in 2014, Sargam Communication is your trusted mobile phone dealer specializing in high-quality mobile phones, travel chargers, and earphone earbuds. Our mission is to provide our customers with the latest and most reliable mobile technology products that enhance their connectivity and convenience. Whether you're in need of a new smartphone, essential accessories, or durable chargers, we have a wide selection to meet your needs.
            </p>
          </section>
          <img src={about1} alt="Mobile phones and accessories" className="w-full md:w-96 object-cover rounded-lg shadow-md  border-red-50 border-4" />
        </div>
        <div className="flex flex-col md:flex-row mb-8 gap-8">
          <img src={about2} alt="Our journey" className="w-full md:w-96 object-cover rounded-lg shadow-md  border-red-50 border-4" />
          <section className="flex-1">
            <h2 className="text-3xl font-semibold text-red-500 mb-4">Our Journey</h2>
            <p className="text-gray-700 text-lg leading-relaxed">
              Since our establishment in 2014, Sargam Communication has been committed to offering top-notch products and exceptional customer service. We pride ourselves on staying up-to-date with the latest trends in mobile technology and ensuring that our customers have access to the best products on the market.
            </p>
          </section>
        </div>
      </div>
      <section className="mx-auto">
        <h2 className="text-3xl font-semibold text-red-500 mb-8 text-center">Why Choose Us?</h2>
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8 max-w-ful mx-auto">
          {/* Card 1 */}
          <div className="flex flex-col items-start text-center md:text-left bg-white p-6 rounded-lg shadow-md border-red-50 border-4">
            <div className="bg-blue-500 text-white h-12 w-12 rounded-full flex items-center justify-center text-2xl mb-4">
              <FaMobileAlt />
            </div>
            <h3 className="text-xl font-semibold text-gray-800 mb-2">Wide Selection</h3>
            <p className="text-gray-700">
              From cutting-edge mobile phones to essential accessories, our inventory is curated to offer the best in mobile technology.
            </p>
          </div>
          {/* Card 2 */}
          <div className="flex flex-col items-start text-center md:text-left bg-white p-6 rounded-lg shadow-md border-red-50 border-4">
            <div className="bg-blue-500 text-white h-12 w-12 rounded-full flex items-center justify-center text-2xl mb-4">
              <FaShieldAlt />
            </div>
            <h3 className="text-xl font-semibold text-gray-800 mb-2">Quality Assurance</h3>
            <p className="text-gray-700">
              We source our products from trusted manufacturers to ensure durability and performance.
            </p>
          </div>
          {/* Card 3 */}
          <div className="flex flex-col items-start text-center md:text-left bg-white p-6 rounded-lg shadow-md border-red-50 border-4">
            <div className="bg-blue-500 text-white h-12 w-12 rounded-full flex items-center justify-center text-2xl mb-4">
              <FaUserFriends />
            </div>
            <h3 className="text-xl font-semibold text-gray-800 mb-2">Customer-Centric Approach</h3>
            <p className="text-gray-700">
              Our knowledgeable and friendly staff are here to help you find exactly what you need and provide support for any queries or issues.
            </p>
          </div>
          {/* Card 4 */}
          <div className="flex flex-col items-start text-center md:text-left bg-white p-6 rounded-lg shadow-md border-red-50 border-4">
            <div className="bg-blue-500 text-white h-12 w-12 rounded-full flex items-center justify-center text-2xl mb-4">
              <FaDollarSign />
            </div>
            <h3 className="text-xl font-semibold text-gray-800 mb-2">Competitive Pricing</h3>
            <p className="text-gray-700">
              We offer competitive prices without compromising on quality, making sure you get the best value for your money.
            </p>
          </div>
        </div>
      </section>

    </div>
  );
}

export default AboutUs;
